<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <el-tabs v-model="activeTab" class="tabs" style="font-size: 1.2em;">
        <el-tab-pane :label="__('Text-to-Speech')" name="tts_settings">
          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.prompt_text"
            :label="__('Prompt')"
          >
            <input-variable-popper
              :value="promptText"
              is-content-editable
              @input="updateText('prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scrollRef=".node-modal-cloud_stt"
            />
            <audio-player
              class="audio-player"
              :disabled="!promptText"
              @get-preview="
                generateAudio(
                  'promptText',
                  'generatingAudioForPrompt',
                  'promptAudioFile',
                  'promptAtAudioFileCreation'
                )
              "
              :show-reload="promptContentChanged"
              :generating-audio="generatingAudioForPrompt"
              :file="promptAudioFile"
            />
          </el-form-item>

          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.fallback_prompt_text"
            :label="__('Fallback Prompt')"
          >
            <input-variable-popper
              :value="fallbackPromptText"
              is-content-editable
              @input="updateText('fallback_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scrollRef=".node-modal-cloud_stt"
            />
            <audio-player
              class="audio-player"
              :disabled="!fallbackPromptText"
              @get-preview="
                generateAudio(
                  'fallbackPromptText',
                  'generatingAudioForFallbackPrompt',
                  'fallbackPromptAudioFile',
                  'fallbackPromptAtAudioFileCreation'
                )
              "
              :show-reload="fallbackPromptContentChanged"
              :generating-audio="generatingAudioForFallbackPrompt"
              :file="fallbackPromptAudioFile"
            />
          </el-form-item>
          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.no_input_prompt_text"
            :label="__('No Input Prompt')"
          >
            <input-variable-popper
              :value="noInputPromptText"
              is-content-editable
              @input="updateText('no_input_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scrollRef=".node-modal-cloud_stt"
            />
            <audio-player
              class="audio-player"
              :disabled="!noInputPromptText"
              @get-preview="
                generateAudio(
                  'noInputPromptText',
                  'generatingAudioForNoInputPrompt',
                  'noInputPromptAudioFile',
                  'noInputPromptAtAudioFileCreation'
                )
              "
              :show-reload="noInputPromptContentChanged"
              :generating-audio="generatingAudioForNoInputPrompt"
              :file="noInputPromptAudioFile"
            />
          </el-form-item>
          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.no_match_prompt_text"
            :label="__('No Match Prompt')"
          >
            <input-variable-popper
              :value="noMatchPromptText"
              is-content-editable
              @input="updateNoMatchPromptText($event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scrollRef=".node-modal-cloud_stt"
            />
            <audio-player
              class="audio-player"
              :disabled="!noMatchPromptText"
              @get-preview="
                generateAudio(
                  'noMatchPromptText',
                  'generatingAudioForNoMatchPrompt',
                  'noMatchPromptAudioFile',
                  'noMatchPromptAtAudioFileCreation'
                )
              "
              :show-reload="noMatchPromptContentChanged"
              :generating-audio="generatingAudioForNoMatchPrompt"
              :file="noMatchPromptAudioFile"
            />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Phrase Hints')" name="phrase_hints">
          <el-form-item
            prop="cloud_stt_node.data.phrase_hints"
            :label="__('Phrase Hints')"
          >
            <div class="phraseHintEditor">
              <input-variable-popper
                id="cloudSTTPhraseHintInput"
                :value="phraseHints"
                v-permit="allowedRegex"
                is-text-area
                :ats="['{{']"
                popper-class="prompt-node-popper"
                @input="updatePhraseHints($event)"
                scrollRef=".node-modal-cloud_stt"
                :include-secure-variables="false"
                force-reinitialize
              />
            </div>
          </el-form-item>

          <el-form-item :label="__('Phrase hints Datastore')">
            <el-select
              v-model="nodeToBind.cloud_stt_node.data.phrase_hints_ds_id"
              style="width: 100%;"
              @change="onDataStoreChange"
              :loading="dataStoresLoading"
              :placeholder="placeHolderDS"
              default-first-option
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in dataStores"
                :label="item.display_name"
                :value="item.data_store_id"
                :key="index"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            :label="__('Datastore phrase hints column')"
            prop="cloud_stt_node.data.ds_phrase_hints_column"
          >
            <el-select
              v-model="nodeToBind.cloud_stt_node.data.ds_phrase_hints_column"
              style="width: 100%;"
              :placeholder="__('Select phrase hints column from Datastore')"
              default-first-option
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in dsColumnList('all')"
                :label="item.col_name"
                :value="item.col_name"
                :key="index"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            :label="__('Datastore boost column')"
            prop="cloud_stt_node.data.ds_boost_column"
          >
            <el-select
              v-model="nodeToBind.cloud_stt_node.data.ds_boost_column"
              style="width: 100%;"
              :placeholder="__('Select boost column from Datastore')"
              default-first-option
              filterable
              clearable
            >
              <el-option-group
                v-for="group in dsColumnWithVariables"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="(item, index) in group.options"
                  :key="index"
                  :label="item.column_name"
                  :value="item.column_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Speech Recognizer')" name="sr_settings">
          <el-form-item
            prop="cloud_stt_node.data.provider"
            :label="__('Provider')"
          >
            <el-radio-group
              v-model="nodeToBind.cloud_stt_node.data.provider"
              @change="handleChangeProvider"
            >
              <el-radio-button label="google">Google</el-radio-button>
              <el-radio-button label="deepgram">Deepgram</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="cloud_stt_node.data.language_id"
            :label="__('Language')"
          >
            <el-select
              v-model="selectedLanguageId"
              style="width: 100%;"
              :placeholder="__('Please select a language')"
              default-first-option
              filterable
              clearable
              v-loading="asrLanguagesLoading"
              :no-data-text="noText"
              :loading-text="__('fetching languages')"
            >
              <el-option
                v-for="(item, index) in filteredLanguages"
                :label="item.language_name"
                :value="item.language_id"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.asr_model"
            :label="__('Speech Recognition Model')"
          >
            <speech-recognition-model
              ref="speech-recognition-model"
              :asr-model="
                nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                  .asr_model
              "
              :asr-model-filter="availableAsrModels"
              @update-asr-model="updateAsrModel"
            >
            </speech-recognition-model>
          </el-form-item>
          <language-settings
            :language-variable="
              nodeToBind.cloud_stt_node.data.language_variable_id || 0
            "
            :alternate-language-select-disabled="
              alternateLanguageSelectDisabled
            "
            :selected-language-ids="alternateLanguageIds"
            :filtered-languages="filteredLanguages"
            :current-select="currentSelectionDetectedLanguageToVariable"
            :show-alternate-language-options="providerIsGoogle"
            @language-var-change="handleLanguageVariableChange"
            @input-languages-change="handleInputLanguagesChange"
            @detect-lang-var-change="handleDetectedLanguageVariableChange"
            ref="language_settings"
          ></language-settings>

          <el-form-item :label="__('Assign transcribed text to variable')">
            <create-or-select
              :items="singleValuedAndSecureVariables"
              label="variable_name"
              value="variable_id"
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :current_select="currentSelectionTranscribedTextToVariable"
              @change="handleChangeTranscribedTextToVariable"
            />
            <el-col :span="4">
              <span
                class="row-message"
                v-if="newVariableForTranscribedTextVar"
                >{{ __("new variable") }}</span
              >
            </el-col>
          </el-form-item>
          <el-form-item :label="__('Assign confidence score to variable')">
            <create-or-select
              :items="singleValuedAndSecureVariables"
              label="variable_name"
              value="variable_id"
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :current_select="currentSelectionConfidenceToVariable"
              @change="handleChangeConfidenceToVariable"
            />
            <el-col :span="4">
              <span class="row-message" v-if="newVariableForConfidenceVar">{{
                __("new variable")
              }}</span>
            </el-col>
          </el-form-item>
          <el-form-item :label="__('Assign recording file to audio variable')">
            <create-or-select
              :items="audioVariables"
              label="variable_name"
              value="variable_id"
              :placeholder="__('Select or create a new audio variable')"
              :new-item-message="__('new variable')"
              :current_select="currentSelectionRecordFileToAudioVariable"
              @change="handleChangeRecordFileToAudioVariable"
            />
            <el-col :span="4">
              <span class="row-message" v-if="newVariableForAudioVar">{{
                __("new audio variable")
              }}</span>
            </el-col>
          </el-form-item>
          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.barge_in"
            :label="__('Speech Controls')"
          >
            <el-checkbox
              v-model="
                nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                  .barge_in
              "
              style="margin-left: 2px;"
              >{{ __("Barge In") }}
            </el-checkbox>
          </el-form-item>
          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.confidence_level"
            :label="__('Minimum Transcription Confidence Score')"
          >
            <el-input-number
              v-model="
                nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                  .confidence_level
              "
              :precision="2"
              :min="0.0"
              :step="0.05"
              :max="1"
              :step-strictly="false"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.interdigit_timeout"
            :label="__('Interdigit Timeout (Seconds)')"
          >
            <el-input-number
              v-model="
                nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                  .interdigit_timeout
              "
              :precision="1"
              :min="0.5"
              :step="0.5"
              :max="10"
              :step-strictly="true"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            prop="cloud_stt_node.data.user_input_nodes_setting.data.max_alternatives"
            :label="__('Max alternative transcriptions')"
            v-if="providerIsGoogle"
            ref="max_alternatives"
          >
            <el-input-number
              v-model="
                nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                  .max_alternatives
              "
              :precision="0"
              :min="1"
              :step="1"
              :max="10"
              :step-strictly="true"
            ></el-input-number>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Event Handler')" name="event_handler">
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <el-form-item
                prop="cloud_stt_node.data.user_input_nodes_setting.data.no_input_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noInputEventHandlerCanvas"
                  :count="
                    nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                      .no_input_count
                  "
                  :eventHandlerLabel="__('No Input Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no input event handler canvas')
                  "
                  @update-event-handler="setNoInputEventHandlerCanvas($event)"
                  @update-event-handler-count="
                    setNoInputEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
              <el-form-item
                prop="cloud_stt_node.data.user_input_nodes_setting.data.no_match_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noMatchEventHandlerCanvas"
                  :count="
                    nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                      .no_match_count
                  "
                  :eventHandlerLabel="__('No Match Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no match event handler canvas')
                  "
                  @update-event-handler="setNoMatchEventHandlerCanvas($event)"
                  @update-event-handler-count="
                    setNoMatchEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Advanced ASR Settings')"
          name="advanced_asr_settings"
        >
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <advanced-speech-parameters
                :speech-complete-timeout="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .speech_complete_timeout
                "
                :speech-incomplete-timeout="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .speech_incomplete_timeout
                "
                :no-input-timeout="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .no_input_timeout
                "
                :speech-start-timeout="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .speech_start_timeout
                "
                :inter-result-timeout="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .inter_result_timeout
                "
                :use-energy-based-barge-in="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .use_energy_based_barge_in
                "
                :barge-in-sensitivity="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .barge_in_sensitivity
                "
                :auto-punctuation="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .auto_punctuation
                "
                :profanity-filter="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .profanity_filter
                "
                :single-utterance="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .single_utterance
                "
                :node-type="'cloudSTTNode'"
                :provider="nodeToBind.cloud_stt_node.data.provider"
                :barge-in="
                  nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
                    .barge_in
                "
                @update-nlp-parameter="updateNlpParameters"
              >
              </advanced-speech-parameters>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Confirmation')" name="confirm_settings">
          <speech-input-confirmation
            @update-max-number-of-input-count="setMaxNumberOfInputCount($event)"
            :showNoInputTimeout="false"
            :documentRule="inputNodeSetting"
            :taskId="task_id"
            scroll-ref=".node-modal-cloud_stt"
          />
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import AudioPlayer from "@/components/AudioPlayer";
import { phraseHintsRegex } from "@/utils/regex";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import EventHandlers from "@/views/build/callflow/components/node-type-forms/components/EventHandlers";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
import AdvancedSpeechParameters from "@/components/AdvancedSpeechParameters.vue";
import SpeechInputConfirmation from "@/views/build/callflow/components/audioInputConfirmation/ConfirmationElement.vue";
import {
  ASR_MODELS_SUPPORTING_ALTERNATE_LANGUAGES,
  getAsrModelsFromLanguage
  // getDefaultAsrModelFromLanguage
} from "@/utils/AsrModels";
import LanguageSettings from "@/components/LanguageSettings";
import SpeechRecognitionModel from "@/views/build/callflow/components/node-type-forms/components/SpeechRecognitionModel";
import { eventHandlerNameValidation } from "@/utils/formValidationRules";
import { sanitizeInput } from "@/utils/promptText";

const initCloudSttNode = {
  node_type: NODE_TYPES.CLOUD_STT.NODE_TYPE,
  menu_node: {
    data: {
      property_rules: {
        data: {
          transfer_phone_number: "",
          display_name: "",
          ani: ""
        }
      },
      transfer_timeout: null,
      no_answer_busy_canvas_id: null
    }
  }
};

const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false,
  variable_type: "audio"
};

const eventHandlerCanvasInitialize = {
  canvas_name: "",
  canvas_id: -1,
  msg: ""
};

export default {
  components: {
    SpeechRecognitionModel,
    LanguageSettings,
    InputVariablePopper,
    AudioPlayer,
    CreateOrSelect,
    EventHandlers,
    AdvancedSpeechParameters,
    SpeechInputConfirmation
  },
  mixins: [BaseNode, GenerateAudio],
  data() {
    const validateNoInputEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "input");
    };
    const validateNoMatchEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "match");
    };

    /**
     * Generic validator for values that depends on DsPhraseHint
     */
    const validateAgainstDsPhraseHint = model => {
      const value = _.get(this.nodeToBind.cloud_stt_node.data, model);
      const phraseHintDs = this.nodeToBind.cloud_stt_node.data
        .phrase_hints_ds_id;
      const phraseHintEmpty = phraseHintDs === "" || phraseHintDs === null;

      return _.isEmpty(value) && !phraseHintEmpty;
    };

    /**
     * Validator for the "datastore phrase hints column" input
     */
    const validateDsPhraseHintsColumn = (rule, value, callback) => {
      validateAgainstDsPhraseHint("ds_phrase_hints_column")
        ? callback("Datastore phrase hints column cannot be empty")
        : callback();
    };

    /**
     * Validator for the "datastore boost column" input
     */
    const validateDsBoostColumn = (rule, value, callback) => {
      validateAgainstDsPhraseHint("ds_boost_column")
        ? callback("Datastore boost column cannot be empty")
        : callback();
    };

    return {
      preText: "",
      rules: {
        "cloud_stt_node.data.user_input_nodes_setting.data.no_input_canvas_id": [
          {
            validator: validateNoInputEventHandler,
            trigger: "change"
          }
        ],
        "cloud_stt_node.data.user_input_nodes_setting.data.no_match_canvas_id": [
          {
            validator: validateNoMatchEventHandler,
            trigger: "change"
          }
        ],
        "cloud_stt_node.data.user_input_nodes_setting.data.prompt_text": [
          {
            required: true,
            message: __("Please add prompt text"),
            trigger: "blur"
          }
        ],

        "cloud_stt_node.data.user_input_nodes_setting.data.fallback_prompt_text": [
          {
            required: true,
            message: __("Please add fallback prompt text"),
            trigger: "blur"
          }
        ],
        "cloud_stt_node.data.user_input_nodes_setting.data.asr_model": [
          {
            required: true,
            message: __("Please select a speech recognition model"),
            trigger: "change"
          }
        ],
        "cloud_stt_node.data.language_id": [
          {
            required: true,
            message: __("Please select a language"),
            trigger: "change"
          }
        ],
        "cloud_stt_node.data.user_input_language": [
          {
            required: true,
            message: __("Please select a language"),
            trigger: "blur"
          }
        ],
        "cloud_stt_node.data.ds_phrase_hints_column": [
          {
            validator: validateDsPhraseHintsColumn,
            trigger: "change"
          }
        ],
        "cloud_stt_node.data.ds_boost_column": [
          {
            validator: validateDsBoostColumn,
            trigger: "change"
          }
        ]
      },
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize),
      activeTab: "tts_settings",
      promptAudioFile: "",
      fallbackPromptAudioFile: "",
      noInputPromptAudioFile: "",
      noMatchPromptAudioFile: "",
      generatingAudioForPrompt: false,
      generatingAudioForFallbackPrompt: false,
      generatingAudioForNoInputPrompt: false,
      generatingAudioForNoMatchPrompt: false,
      promptAtAudioFileCreation: "",
      fallbackPromptAtAudioFileCreation: "",
      noInputPromptAtAudioFileCreation: "",
      noMatchPromptAtAudioFileCreation: "",
      allowedRegex: phraseHintsRegex,
      newVariableForAudioVar: 0,
      newVariableForTranscribedTextVar: 0,
      newVariableForConfidenceVar: 0,
      newVariableForDetectedLanguageVar: 0,
      transcribeTextRuleValue: "sys.cloud_stt_transcribed_text_var",
      confidenceRuleValue: "sys.cloud_stt_confidence_var",
      userUtteranceAudioRuleValue: "sys.cloud_stt_audio_var",
      detectedLanguageRuleValue: "sys.cloud_stt_detected_lang_var",
      tabStructure: {
        tts_settings: [
          "cloud_stt_node.data.user_input_nodes_setting.data.prompt_text",
          "cloud_stt_node.data.user_input_nodes_setting.data.fallback_prompt_text"
        ],
        sr_settings: ["cloud_stt_node.data.user_input_language"],
        event_handler: [
          "cloud_stt_node.data.user_input_nodes_setting.data.no_input_canvas_id",
          "cloud_stt_node.data.user_input_nodes_setting.data.no_match_canvas_id"
        ],
        add_settings: ["cloud_stt_node.data.language_variable_id"]
      },
      cloudSTTNodeResponse: {
        ws_response_data: {
          raw_transcribe: "",
          confidence_score: "",
          additional_information: {
            alternate_transcriptions: [
              {
                transcription: "",
                confidence: ""
              }
            ]
          }
        },
        ws_response_meta: []
      },
      noDataTextDS: __("Couldn't find a Datastore in your account"),
      placeHolderDS: __("Please select a Datastore for phrase hints"),
      prevSelectedASRModel: {}
    };
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: "selectedAccountId"
    }),
    ...mapState("prompts", {
      prompts: state => state.prompts,
      promptsLoading: state => state.loading
    }),
    ...mapGetters("canvas", {
      getEventHandlerCanvasList: "getEventHandlerCanvasList"
    }),
    ...mapState("asrLanguages", {
      asrLanguages: state => state.asrLanguages,
      asrLanguagesLoading: state => state.loading
    }),
    ...mapState("dataStores", {
      dataStores: state => state.dataStores,
      dataStoresLoading: state => state.loading
    }),

    providerIsGoogle() {
      return this.nodeToBind.cloud_stt_node.data.provider === "google";
    },

    noInputEventHandlerCanvas() {
      return this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
        .no_input_canvas_id;
    },
    noMatchEventHandlerCanvas() {
      return this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
        .no_match_canvas_id;
    },

    /**
     * Get the user input lad
     */
    alternateLanguageIds: {
      get() {
        let userInputLanguageData =
          (this.nodeToBind.cloud_stt_node.data.user_input_language || {})
            .data || [];
        // If userInputLanguageData is an array of ID's return that array
        // If userInputLanguageData is an associative array of ID's,
        // parse each key as an int then, return the keys of that array
        if (Array.isArray(userInputLanguageData)) {
          return userInputLanguageData;
        } else {
          return Object.keys(userInputLanguageData).map(key => parseInt(key));
        }
      }
    },

    /**
     * Returns true if the selected ASR model does not support alternate languages.
     */
    alternateLanguageSelectDisabled: {
      get() {
        return !ASR_MODELS_SUPPORTING_ALTERNATE_LANGUAGES.includes(
          this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
            .asr_model
        );
      }
    },

    /**
     * Getter-setter for the ID of the selected language
     */
    selectedLanguageId: {
      get() {
        return _.get(this.nodeToBind, "cloud_stt_node.data.language_id");
      },

      set(val) {
        this.$set(this.nodeToBind.cloud_stt_node.data, "language_id", val);
      }
    },

    /**
     * Get the available ASR models from the current selected CSTT language
     */
    availableAsrModels() {
      return getAsrModelsFromLanguage(
        this.filteredLanguages,
        this.selectedLanguageId,
        this.nodeToBind.cloud_stt_node.data.provider
      );
    },

    inputNodeSetting: {
      get: function() {
        return _.get(
          this.nodeToBind.cloud_stt_node.data,
          "user_input_nodes_setting",
          {}
        );
      },
      set: function(value) {
        this.$set(
          this.nodeToBind.cloud_stt_node.data,
          "user_input_nodes_setting",
          value
        );
      }
    },
    dsColumnWithVariables() {
      return [
        {
          label: __("Datastore Columns"),
          options: _.map(this.dsColumnList("all"), column => {
            return {
              column_id: column.col_name,
              column_name: column.col_name
            };
          })
        },
        {
          label: __("Variables"),
          options: _.map(this.singleValuedVariables, variable => {
            return {
              column_id: "{{" + variable.variable_id + "}}",
              column_name: variable.variable_name
            };
          })
        }
      ];
    },
    dsColumnList() {
      return type => {
        let result = [];
        if (this.nodeToBind.cloud_stt_node.data.phrase_hints_ds_id) {
          const dataStoresCloned = _.cloneDeep(this.dataStores);
          let filterDataStore = _.filter(dataStoresCloned, {
            data_store_id: this.nodeToBind.cloud_stt_node.data
              .phrase_hints_ds_id
          });
          if (filterDataStore.length > 0) {
            if (type === "dtmf") {
              result = _.filter(
                filterDataStore[0].collection_structure,
                function(chr) {
                  return chr.type === "integer";
                }
              );
            } else {
              result = _.filter(
                filterDataStore[0].collection_structure,
                function(chr) {
                  return chr.type !== "audio";
                }
              );
            }
          }
        }
        return result;
      };
    },
    promptText() {
      return this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
        .prompt_text;
    },
    fallbackPromptText() {
      return this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
        .fallback_prompt_text;
    },
    noInputPromptText() {
      return sanitizeInput(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
          .no_input_prompt_text
      );
    },
    noMatchPromptText() {
      return sanitizeInput(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
          .no_match_prompt_text
      );
    },
    phraseHints() {
      return this.nodeToBind.cloud_stt_node.data.phrase_hints;
    },

    formParameters() {
      return this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
        .form_parameters;
    },
    getOptionsInitiators() {
      return ["{{", "[["];
    },
    promptContentChanged() {
      return this.promptText !== this.promptAtAudioFileCreation;
    },
    fallbackPromptContentChanged() {
      return this.fallbackPromptText !== this.fallbackPromptAtAudioFileCreation;
    },
    noInputPromptContentChanged() {
      return this.noInputPromptText !== this.noInputPromptAtAudioFileCreation;
    },
    noMatchPromptContentChanged() {
      return this.noMatchPromptText !== this.noMatchPromptAtAudioFileCreation;
    },
    noText() {
      return (
        this.preText +
        " ASR languages are not provisioned in your business plan"
      );
    },
    //  filters language based on the NLP engine selected
    //  return Array
    filteredLanguages() {
      let asrLanguages = [];
      let self = this;

      if (this.nodeToBind.cloud_stt_node.data.provider === "deepgram") {
        self.preText = __("Deepgram");
        asrLanguages = _.filter(this.asrLanguages, function(o) {
          return (
            o.language_provider === "deepgram" &&
            o.language_used_in.includes("cloud_speech")
          );
        });
      } else {
        // Default case is set to google.
        self.preText = __("Google Cloud Speech to Text");
        asrLanguages = _.filter(this.asrLanguages, function(o) {
          return (
            o.language_provider === "google" &&
            o.language_used_in.includes("cloud_speech")
          );
        });
      }
      return asrLanguages || [];
    },
    currentSelectionRecordFileToAudioVariable() {
      let variableRules = _.find(
        this.nodeToBind.cloud_stt_node.data.variable_rules.data,
        rule => rule.rule_value === this.userUtteranceAudioRuleValue
      );

      const { variable_id, variable_name } = _.isEmpty(variableRules)
        ? this.variableRuleInitialize
        : variableRules;
      return variable_id === -1 ? variable_name : variable_id;
    },

    currentSelectionTranscribedTextToVariable() {
      let variableRules = _.find(
        this.nodeToBind.cloud_stt_node.data.variable_rules.data,
        rule => rule.rule_value === this.transcribeTextRuleValue
      );

      const { variable_id, variable_name } = _.isEmpty(variableRules)
        ? this.variableRuleInitialize
        : variableRules;
      return variable_id === -1 ? variable_name : variable_id;
    },

    currentSelectionConfidenceToVariable() {
      let variableRules = _.find(
        this.nodeToBind.cloud_stt_node.data.variable_rules.data,
        rule => rule.rule_value === this.confidenceRuleValue
      );

      const { variable_id, variable_name } = _.isEmpty(variableRules)
        ? this.variableRuleInitialize
        : variableRules;
      return variable_id === -1 ? variable_name : variable_id;
    },

    currentSelectionDetectedLanguageToVariable() {
      let variableRules = _.find(
        this.nodeToBind.cloud_stt_node.data.variable_rules.data,
        rule => rule.rule_value === this.detectedLanguageRuleValue
      );

      const { variable_id, variable_name } = _.isEmpty(variableRules)
        ? this.variableRuleInitialize
        : variableRules;
      return variable_id === -1 ? variable_name : variable_id;
    },
    getVariableRules: function() {
      return _.filter(
        this.nodeToBind.cloud_stt_node.data.variable_rules.data,
        function(v) {
          return v.variable_type !== "audio";
        }
      );
    },
    transferPhoneNumber: {
      get() {
        return (
          this.nodeToBind.cloud_stt_node.data.property_rules.data
            .transfer_phone_number || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.cloud_stt_node.data.property_rules.data.transfer_phone_number = expression;
      }
    },

    ani: {
      get() {
        return (
          this.nodeToBind.cloud_stt_node.data.property_rules.data.ani || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.cloud_stt_node.data.property_rules.data.ani = expression;
      }
    },

    displayName: {
      get() {
        return (
          this.nodeToBind.cloud_stt_node.data.property_rules.data
            .display_name || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.cloud_stt_node.data.property_rules.data.display_name = expression;
      }
    },

    languageVariable: {
      get: function() {
        return this.nodeToBind.cloud_stt_node.data.language_variable_id || -1;
      },
      set: function(val) {
        this.$set(
          this.nodeToBind.cloud_stt_node.data,
          "language_variable_id",
          val
        );
      }
    }
  },
  async created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.cloud_stt_node)) {
      this.initializeCloudSTTNodeData();
      this.$set(this.nodeToBind, "cloud_stt_node", {});
      this.$set(this.nodeToBind.cloud_stt_node, "data", {});
      this.selectedLanguageId = null;
      this.$set(this.nodeToBind.cloud_stt_node.data, "user_input_language", {});
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_language,
        "data",
        []
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data,
        "language_variable_id",
        null
      );
      this.$set(this.nodeToBind.cloud_stt_node.data, "provider", "google");
      this.$set(this.nodeToBind.cloud_stt_node.data, "phrase_hints", "");
      this.$set(
        this.nodeToBind.cloud_stt_node.data,
        "phrase_hints_ds_id",
        null
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data,
        "ds_phrase_hints_column",
        null
      );
      this.$set(this.nodeToBind.cloud_stt_node.data, "ds_boost_column", null);

      this.$set(this.nodeToBind.cloud_stt_node.data, "variable_rules", {});
      this.$set(this.nodeToBind.cloud_stt_node.data.variable_rules, "data", []);
      this.$set(
        this.nodeToBind.cloud_stt_node.data,
        "user_input_nodes_setting",
        {}
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting,
        "data",
        {
          ...SpeechInputConfirmation.default_value
        }
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "barge_in",
        false
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_match_count",
        2
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_input_count",
        2
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "fallback_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_input_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_match_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "form_parameters",
        ""
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "confidence_level",
        0.5
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "interdigit_timeout",
        2.0
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "max_alternatives",
        1
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );

      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "override_form_nlp_params",
        false
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "speech_complete_timeout",
        1000
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "speech_incomplete_timeout",
        20000
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_input_timeout",
        5000
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "speech_start_timeout",
        50
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "inter_result_timeout",
        0
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "use_energy_based_barge_in",
        false
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "barge_in_sensitivity",
        0.5
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "auto_punctuation",
        false
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "profanity_filter",
        false
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "single_utterance",
        true
      );
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "override_form_nlp_params",
        false
      );

      // Init default ASR model
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "asr_model",
        ""
      );
      this.$set(this.nodeToBind, "node_type", NODE_TYPES.CLOUD_STT.NODE_TYPE);
    } else {
      if (
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
          .no_input_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
          "no_input_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noInputEventHandlerCanvas
          })
        );
      }

      if (
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data
          .no_match_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
          "no_match_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noMatchEventHandlerCanvas
          })
        );
      }

      if (
        !_.has(this.nodeToBind.cloud_stt_node.data, "user_input_nodes_setting")
      ) {
        this.$set(
          this.nodeToBind.cloud_stt_node.data,
          "user_input_nodes_setting",
          {}
        );
        this.$set(
          this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting,
          "data",
          {
            ...SpeechInputConfirmation.default_value
          }
        );
      }
    }
    await this.initializeLanguages();
    this.initializePrompts();
    await this.initializeDataStores();
    await this.checkASRLanguagesLength();

    // // Make sure ASR model has value if older data is loaded without the ASR model property
    // AdvancedSpeechParameters.setAsrModelSelectorDefaultValue(
    //   this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
    //   this.filteredLanguages,
    //   this.selectedLanguageId,
    //   this.$set
    // );

    this.prevSelectedASRModel = {
      [this.selectedLanguageId]: _.get(
        this.nodeToBind,
        "cloud_stt_node.data.user_input_nodes_setting.data.asr_model",
        ""
      )
    };
  },
  methods: {
    ...mapActions("prompts", {
      getPrompts: "getPrompts"
    }),
    ...mapActions("asrLanguages", {
      getASRLanguages: "getASRLanguages"
    }),
    ...mapActions("dataStores", {
      getDataStores: "getDataStores",
      resetDataStores: "resetDataStores"
    }),
    initializeCloudSTTNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initCloudSttNode)
      );
    },
    initializePrompts() {
      if (!this.prompts.length) {
        this.getPrompts();
      }
    },
    async initializeLanguages() {
      await this.getASRLanguages({ fetch_all: 0 });
    },
    async checkASRLanguagesLength() {
      if (!this.asrLanguages.length && !this.asrLanguagesLoading) {
        this.$message({
          type: "error",
          // eslint-disable-next-line
          message: __("ASR languages are not provisioned in your business plan")
        });
      }
    },
    async initializeDataStores() {
      this.getDataStores({ fetch_all: 1, is_log: 0 });
    },
    updateText(key, value) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        key,
        sanitizeInput(value)
      );
    },
    updateNoMatchPromptText(value) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_match_prompt_text",
        sanitizeInput(value)
      );
    },
    setMaxNumberOfInputCount(val) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_max_number_of_input_count",
        val
      );
    },
    updatePhraseHints(value) {
      this.$set(this.nodeToBind.cloud_stt_node.data, "phrase_hints", value);
    },
    onDataStoreChange() {
      this.nodeToBind.cloud_stt_node.data.ds_phrase_hints_column = null;
      this.nodeToBind.cloud_stt_node.data.ds_boost_column = null;
    },
    arrayRule() {
      let arrayRule = _.find(
        this.getVariableRules,
        rule => rule.variable_name === this.arrayVariableName
      );
      let { variable_name, variable_id } = this.arrayVariable;
      if (!arrayRule) {
        arrayRule = {
          rule_value: "root",
          default_value: "",
          variable_name,
          variable_id,
          array_variable: true,
          variable_type: "array"
        };
        this.nodeToBind.cloud_stt_node.data.variable_rules.data.push(arrayRule);
      }
      return arrayRule;
    },
    setCloudSTTNodeJsonResponse() {
      let rule = this.arrayRule();
      rule.default_value = JSON.stringify(this.cloudSTTNodeResponse);
    },
    handleChangeRecordFileToAudioVariable(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForAudioVar = _.isEmpty(option.msg) ? 0 : 1;
      const recordFileAudioVarObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "audio",
        msg: option.msg,
        rule_value: this.userUtteranceAudioRuleValue
      };
      if (_.isEmpty(this.nodeToBind.cloud_stt_node.data.variable_rules)) {
        this.$set(this.nodeToBind.cloud_stt_node.data, "variable_rules", {});
        this.$set(
          this.nodeToBind.cloud_stt_node.data.variable_rules,
          "data",
          []
        );
      } else {
        _.remove(
          this.nodeToBind.cloud_stt_node.data.variable_rules.data,
          obj => obj.rule_value === this.userUtteranceAudioRuleValue
        );
      }
      this.nodeToBind.cloud_stt_node.data.variable_rules.data.push(
        recordFileAudioVarObj
      );
    },

    handleChangeTranscribedTextToVariable(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForTranscribedTextVar = _.isEmpty(option.msg) ? 0 : 1;
      const transcribeTextVarObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "single_value",
        msg: option.msg,
        rule_value: this.transcribeTextRuleValue
      };
      if (_.isEmpty(this.nodeToBind.cloud_stt_node.data.variable_rules)) {
        this.$set(this.nodeToBind.cloud_stt_node.data, "variable_rules", {});
        this.$set(
          this.nodeToBind.cloud_stt_node.data.variable_rules,
          "data",
          []
        );
      } else {
        _.remove(
          this.nodeToBind.cloud_stt_node.data.variable_rules.data,
          obj => obj.rule_value === this.transcribeTextRuleValue
        );
      }
      this.nodeToBind.cloud_stt_node.data.variable_rules.data.push(
        transcribeTextVarObj
      );
    },

    handleChangeConfidenceToVariable(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForConfidenceVar = _.isEmpty(option.msg) ? 0 : 1;
      const confidenceObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "single_value",
        msg: option.msg,
        rule_value: this.confidenceRuleValue
      };
      if (_.isEmpty(this.nodeToBind.cloud_stt_node.data.variable_rules)) {
        this.$set(this.nodeToBind.cloud_stt_node.data, "variable_rules", {});
        this.$set(
          this.nodeToBind.cloud_stt_node.data.variable_rules,
          "data",
          []
        );
      } else {
        _.remove(
          this.nodeToBind.cloud_stt_node.data.variable_rules.data,
          obj => obj.rule_value === this.confidenceRuleValue
        );
      }
      this.nodeToBind.cloud_stt_node.data.variable_rules.data.push(
        confidenceObj
      );
    },

    handleChangeProvider() {
      this.selectedLanguageId = null;
      //this.advanced_asr_rerender_key++;
    },

    setNoInputEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "cloud_stt_node.data.user_input_nodes_setting.data.no_input_canvas_id"
      );
    },
    setNoMatchEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "cloud_stt_node.data.user_input_nodes_setting.data.no_match_canvas_id"
      );
    },

    setNoMatchEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_match_count",
        val
      );
    },
    setNoInputEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "no_input_count",
        val
      );
    },
    cleanUpNodeToPrepareForSubmit() {
      if (this.nodeToBind.cloud_stt_node.data.phrase_hints_ds_id === "") {
        this.nodeToBind.cloud_stt_node.data.phrase_hints_ds_id = null;
        this.nodeToBind.cloud_stt_node.data.ds_phrase_hints_column = null;
        this.nodeToBind.cloud_stt_node.data.ds_boost_column = null;
      }
      this.nodeToBind.cloud_stt_node.data.user_input_language.data = this.alternateLanguageIds;
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method
      // setting node's json schema response to root variable
      let filteredVariableRulesData = filterRowsIfSomeKeyValueIsAbsent(
        this.nodeToBind.cloud_stt_node.data.variable_rules.data,
        "variable_name"
      );
      this.$set(this.nodeToBind.cloud_stt_node.data.variable_rules, "data", []);
      this.nodeToBind.cloud_stt_node.data.variable_rules.data = filteredVariableRulesData;
      this.setCloudSTTNodeJsonResponse();
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    },
    createOrEditNode() {
      if (!this.isTaskReadOnly) {
        this.attemptedToSubmit = true;
        this.$refs.nodeForm.validate((valid, errors) => {
          if (valid) {
            this.process({
              node: this.nodeToBind,
              nodeInContext: this.node
            })
              .then(async () => {
                this.newVariableCreated
                  ? await this.forceFetchVariables()
                  : null;
                this.setClickedNode(null);
                this.toggleNodeSubmit(false);
              })
              .catch(() => {
                // this.newVariableCreated = false;
                this.toggleNodeSubmit(false);
              });
          } else {
            let errMessages = _.flatten(
              _.map(errors, err => {
                return _.map(err, "message");
              })
            );

            _.map(errMessages, message => {
              setTimeout(() => this.errorNotification(message), 100);
            });
            this.toggleNodeSubmit(false);
            // this.newVariableCreated = false;
            let errorPropName = Object.keys(errors);
            let findTab = _.findKey(this.tabStructure, function(structure) {
              return structure.some(r => errorPropName.includes(r));
            });

            if (!_.isEmpty(findTab)) {
              this.activeTab = findTab;
            }
            return false;
          }
        });
      }
    },
    updateAsrModel(val) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "asr_model",
        val
      );
    },
    updateNlpParameters(nlp_param, value, default_value) {
      if (value !== false && value !== 0 && !value) {
        value = default_value;
      }
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        nlp_param,
        value
      );
    },
    handleInputLanguagesChange(value) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_language,
        "data",
        value
      );
    },
    handleLanguageVariableChange(value) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data,
        "language_variable_id",
        value
      );
    },
    handleDetectedLanguageVariableChange(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForDetectedLanguageVar = _.isEmpty(option.msg) ? 0 : 1;
      const detectedLangVarObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "single_value",
        msg: option.msg,
        rule_value: this.detectedLanguageRuleValue
      };
      if (_.isEmpty(this.nodeToBind.cloud_stt_node.data.variable_rules)) {
        this.$set(this.nodeToBind.cloud_stt_node.data, "variable_rules", {});
        this.$set(
          this.nodeToBind.cloud_stt_node.data.variable_rules,
          "data",
          []
        );
      } else {
        _.remove(
          this.nodeToBind.cloud_stt_node.data.variable_rules.data,
          obj => obj.rule_value === this.detectedLanguageRuleValue
        );
      }
      this.nodeToBind.cloud_stt_node.data.variable_rules.data.push(
        detectedLangVarObj
      );
    }
  },

  // Watch gets triggered before computed property
  // Proper check must be in place to ensure that computed data is available before any process
  watch: {
    /**
     * Selected language watcher. Reset the selected ASR model with the languages default
     * Return empty for now, we will change the default Asr model once we have data to suggest which is the most common one
     */
    "nodeToBind.cloud_stt_node.data.language_id": function(newValue) {
      this.$set(
        this.nodeToBind.cloud_stt_node.data.user_input_nodes_setting.data,
        "asr_model",
        this.prevSelectedASRModel[newValue] || ""
        // getDefaultAsrModelFromLanguage(this.filteredLanguages, newValue)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    /*&:focus {*/
    /*  border: 1px solid black;*/
    /*}*/
  }
}
</style>
